@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&family=Sora:wght@400;600;700&display=swap');

:root {
  --background: #F9F9FF;
  --bg-img: url("../src/assets/noise-light.png");
  --text-primary: #010839;
  --text-secondary: #EA4426;
  --accent: #E22300;
  --border: #EBEBF6;
  --nav-bg: #EBEBF6;
  --nav-bb: #d8d8d8;
  --gradient-headline: #be2004 -18.43%, #E22300 31.35%, #ea4426 64.06%, rgba(255, 169, 169, 0.83) 112.56%;
  --portrait: url("https://res.cloudinary.com/dhrtgukmg/image/upload/v1736878775/Portrait_25_white_zsobt8.png");
  --eyes-show:flex;

  @media screen and (max-width: 415px) {
    --eyes-show:none;
  }
}

[data-theme='dark'] {
  --background: #00180E;
  --bg-img: url("../src/assets/noise-dark.png");
  --text-primary: #F8FFFB;
  --text-secondary: #91F2B8;
  --accent: #0aaa7d;
  --border: #F8FFFB;
  --nav-bg: #01110A;
  --nav-bb: #123123;
  --gradient-headline: #0A2519 -18.43%, #0aaa65 31.35%, #28c6b6 64.06%, rgba(191, 255, 236, 0.83) 112.56%;
  --portrait: url('https://res.cloudinary.com/dhrtgukmg/image/upload/v1664182243/P90816portrai61_pfjixi.png');
  --eyes-show:none;
}

@layer base {

  body {
    @apply font-[Sora];
  }

  h5 {
    @apply font-[Caveat];
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }

  h1 {
    font-size: 3rem;
    line-height: 1;
    font-weight: bold;
    color: var(--text-primary);
  }

  h6 {
    color: var(--text-primary);
  }
}

.freedevice {
  background-size:contain;
  background-position: center;
  background-repeat:no-repeat;
  height:500px;
  position:relative;
}

.portrait {
  background-image:var(--portrait);
  background-size:cover;
}

.two-images {
  height:500px;
  background-position: center;
  background-repeat:no-repeat;
  position:absolute;
}

#gymally-left {
  background-image:url(assets/reference_gymally_left.png);
  width:500px;
  z-index:3;
  background-size:cover;
}

#gymally-right {
  background-image:url(assets/reference_gymally_right.png);
  width:500px;
  z-index:4;
  background-size:cover;
}

#bouncy-left {
  background-image:url(assets/reference_burger_left.png);
  width:200px;
  z-index:5;
  background-size:contain;
  left:-2em;
}

#bouncy-right {
  background-image:url(assets/reference_burger_right.png);
  background-size:cover;
  width:500px;
  right:0.5em;
}

#dc-left {
  background-image:url(assets/reference_d+c_left.png);
  width:200px;
  z-index:5;
  background-size:contain;
  left:-1em;
}

#dc-right {
  background-image:url(assets/reference_d+c_right.png);
  background-size:cover;
  width:550px;
  right:0.3em;
}

#civey-left {
  background-image:url(assets/reference_civey_left.png);
  width:200px;
  z-index:5;
  left:2em;
  top:2em;
  background-size:contain;
}

#civey-right {
  background-image:url(assets/reference_civey_right.png);
  z-index:3;
  width:500px;
  background-size:cover;
}

#helplocal-left {
  background-image:url(assets/reference_help_left.png);
  width:440px;
  z-index:3;
  background-size:cover;
}

#helplocal-right {
  background-image:url(assets/reference_help_right.png);
  z-index:5;
  right:8em;
  width:200px;
  background-size:contain;
}

#history-left {
  background-image:url(assets/reference_history_left.png);
  width:500px;
  z-index:3;
  background-size:cover;
}

#history-right {
  background-image:url(assets/reference_history_right.png);
  z-index:5;
  right:-2em;
  top:-2em;
  height:600px;
  width:300px;
  background-size:contain;
}

#lile-left {
  background-image:url(assets/reference_littleleaf_left.png);
  width:200px;
  z-index:5;
  left:2em;
  top:2em;
  background-size:contain;
}

#lile-right {
  background-image:url(assets/reference_littleleaf_right.png);
  z-index:3;
  width:500px;
  background-size:cover;
}

#anita-right {
  background-repeat:no-repeat;
  background-image:url(assets/reference_anita_right.png);
  z-index:5;
  right:2em;
  top:2em;
  width:165px;
  background-size:contain;
}

#anita-left {
  background-image:url(assets/reference_anita_left.png);
  width:500px;
  z-index:3;
  background-size:cover;
}

#rsgg-left {
  background-image:url(assets/reference_rsg_left.png);
  width:500px;
  z-index:3;
  background-size:cover;
}

#rsgg-right {
  background-image:url(assets/reference_rsg_right.png);
  z-index:5;
  right:-2em;
  width:300px;
  background-size:contain;
}


#txtflix-left {
  background-image:url(assets/reference_txtflix_left.png);
  width:500px;
  z-index:3;
  background-size:cover;
}

#txtflix-right {
  background-image:url(assets/reference_txtflix_right.png);
  z-index:5;
  right:0em;
  width:300px;
  background-size:contain;
}

.zoom-in-left-small {
  animation: hoverSmall 5s infinite linear, zoomInL 0.5s ease-in-out;
}

.zoom-in-right-small {
  animation: hoverSmall 5s infinite linear, zoomInR 0.5s ease-in-out;
}

.zoom-in-left-big {
  animation: hoverBig 4s infinite linear, zoomInL 0.5s ease-in-out;
}

.zoom-in-right-big {
  animation: hoverBig 4s infinite linear, zoomInR 0.5s ease-in-out;
}

@keyframes zoomInL {
  0% {
    transform: translate3d(-50px, 0,  0) scale(.8);
}
  100% {
    transform: translate3d(0px,  0, 0) scale(1);
  }
}

@keyframes zoomInR {
  0% {
    transform: translate3d(50px, 0,  0) scale(.75);
}
  100% {
    transform: translate3d(0px,  0, 0) scale(1);
  }
}

@keyframes hoverBig {
  0% {transform: translateY(-9px);}
  50% {transform: translateY(9px);}
  100% {transform: translateY(-9px);}
}

@keyframes hoverSmall {
  0% {transform: translateY(-3px);}
  50% {transform: translateY(3px);}
  100% {transform: translateY(-3px);}
}

@media screen and (max-width: 1000px) {
  .freedevice {
    transform:scale(0.75);
  }
}

@media screen and (max-width: 756px) {
  .freedevice {
    height:300px;
  }

  #civey-right {
    top:-6em;
    right:-2.5em;
  }

  #civey-left {
    top:-5em;
    left:-1em;
  }

  #lile-left {
    top:-5em;
    left:1em;
  }

  #lile-right {
    top:-6em;
    right:-1em;
  }

  #bouncy-right {
    top:-6em;
    right:-2.5em;
  }

  #bouncy-left {
    top:-4em;
    left:-2em;
  }

  #dc-left {
    top:-5em;
    left:-2em;
  }

  #dc-right {
    top:-6em;
    right:-2.5em;
  }


  #anita-left {
    top:-6em;
    left:0em;
  }

  #anita-right {
    top:-4em;
    right:-1em;
  }

  #history-left {
    top:-6em;
    left:-3em;
  }

  #history-right {
    top:-7em;
    left:14em;
  }

  #gymally-left {
    left:-1em;
    top:-5em;
  }

  #gymally-right {
    top:-3.5em;
    left:-1em;
  }

  #rsgg-left {
    top:-6em;
    left:-3em;
  }

  #rsgg-right {
    top:-5em;
    right:-3em;
  }


  #txtflix-left {
    top:-5em;
    left:-2em;
  }

  #txtflix-right {
    top:-5em;
  }
}


@media screen and (max-width: 499px) {
  .freedevice {
    height:343px;
    transform:scale(1);
  }

  .two-images {
    height:343px;
  }

  #civey-right {
    top:0.5em;
    right:-1.5em;
    width:375px;
  }

  #civey-left {
    top:1em;
    left:0.5em;
  }

  #lile-right {
    top:2em;
    right:-0.5em;
    width:375px;
  }

  #lile-left {
    top:2em;
    left:-1.5em;
  }

  #history-left {
    background-size:contain;
    top:2em;
    left:-5.5em;
  }

  #history-right {
    top:2em;
    left:7.5em;
    height:400px;
    width:300px;
  }


  #anita-left {
    background-size:contain;
    top:2em;
    left:-5.5em;
  }

  #anita-right {
    top:2em;
    left:12em;
    width:170px;
  }

  #gymally-left {
    background-size:contain;
    left:-5em;
    top:2.5em;
  }

  #gymally-right {
    background-size:contain;
    top:2em;
    left:-5em;
  }

  #rsgg-left {
    background-size:contain;
    top:2em;
    left:-5em;
  }

  #rsgg-right {
    top:2.5em;
    right:-6em;
  }

  #bouncy-right {
    top:0.5em;
    left:-1.75em;
    height:380px;
    width:380px;
  }

  #bouncy-left {
    top:2em;
    left:-3em;
  }

  #dc-left {
    top:2em;
    left:-2em;
  }

  #dc-right {
    top:0.5em;
    left:-1.75em;
    height:380px;
    width:380px;
  }

  #txtflix-left {
    top:1em;
    left:-0.5em;
    height:390px;
    width:390px;
  }

  #txtflix-right {
    top:2em;
    left:6em;
  }
}

.textlink {
  border-bottom: solid 2px var(--nav-bb);
}

.textinfo {
  vertical-align: middle;
  display:flex;
  flex-direction:column;
  align-items:left;
}

.textinfo p:first-of-type {
  border-bottom: 1.5px solid var(--nav-bb);
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 240px;
  border-radius: 0.5rem;
  box-shadow: 2px 1px 12px -1px var(--nav-bg);
  letter-spacing: 0.05em;
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-left:auto;
  margin-right:auto;
  justify-content: center;
}

.content-div:hover {
  background-image: none !important;
  background-color: var(--nav-bb) !important;
}

.workbutton {
  background: var(--background);
  color: var(--text-primary);
  border: 1.5px solid var(--nav-bg);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size:large;
  margin:0.5rem;
}

.freestyle h4 {
  font-weight:600;
}

.freestyle h5 {
  color:var(--text-primary);
}

.freestyletag {
  background: var(--background);
  color: var(--text-primary);
  border: 1.5px solid var(--nav-bb);
  border-radius: 0.5rem;
  padding: 0.25rem 0.75rem;
  width:fit-content;
}

.freestyletag:hover {
  background: var(--accent);
  border: 1.5px solid var(--accent);
}

.gradient-headline {
  background-color: var(--text-secondary);
  background-image: linear-gradient(49deg, var(--gradient-headline));
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.primary {
  color: var(--text-primary);
  font-weight: medium;
  border: 1.5px solid var(--border);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: var(--background);
}

.primary:hover {
  background: var(--accent);
  border: 1.5px solid var(--accent);
}

.theme-toggle {
  position:fixed;
  top:80px;
  right: 16px;
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  padding:12px;
  border: 1.5px solid var(--nav-bb);
  color: var(--text-primary);
  background: var(--nav-bg);
  border-radius: 24px;
}

.theme-toggle:hover {
  background:var(--nav-bb);
  border: 1.5px solid var(--nav-bb);
}


.theme-toggle h4 {
  margin-left: 8px;
}

.scroll-top {
  position:absolute;
  bottom:-32px;
  right:16px;
  display:block;
  padding:12px;
  border: 1.5px solid var(--nav-bb);
  color: var(--text-primary);
  background: var(--nav-bg);
  border-radius: 24px;
}

.scroll-top:hover {
  color: var(--nav-bg);
  background: var(--accent);
  border: 1.5px solid var(--accent);
}

body {
  color: var(--text-primary);
}

p {
  color: var(--text-primary);
}

h2 {
  font-weight:bold;
  font-size: 2rem;
  border-bottom: solid 4px var(--accent);
  color: var(--text-primary);
}

h3 {
  color: var(--text-primary);
  font-weight:500;
  font-size: 2rem;
}

h4 {
  color: var(--text-primary);
  font-weight:lighter;
  font-size: 1.5rem;
}

h5 {
  color: var(--text-secondary);
  font-size:x-large;
}

.app {
  background-image: var(--bg-img);
  background-size: 200px;
  background-repeat: repeat;
  overflow:hidden;
}

a {
  color: var(--text-primary);
}

.inputfield,
.inputfield:focus,
.inputfield:active {
  background: var(--border);
  color: var(--primary);
  border: none;
  outline: none;
  resize: none;
}

.active {
  background: var(--nav-bg);
  color: var(--text-primary);
  border-bottom: 1px solid var(--nav-bb);
  z-index: 11;
}

.mobinav {
  background: var(--nav-bg);
  position: absolute;
  z-index: 15;
}

.type-effect  {
  z-index: 0;
}

.navicons {
  color: var(--text-primary);
  z-index: 16;
}

.socialicon {
  background: var(--nav-bg);
  border: 1px solid var(--nav-bb);
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 8px;
}

.socialicon a{
  color: var(--text-primary);
}

.dynamic-svg {
  fill: var(--text-primary);
}

.eyes {
  display: var(--eyes-show);
}

.eye {
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}
.eye:after {
  position: absolute;
  bottom: 17px;
  right: 10px;
  width: 10px;
  height: 10px;
  background: #010839;
  border-radius: 50%;
  content: " ";
}
